/**
 * This module provides HTTP operations tailored for interacting with the Backend-API.
 * It adds the client-token to the requests. This client-token is retrieved from local storage.
 */

import type {
    AxiosError,
    AxiosRequestConfig,
    AxiosResponse,
    InternalAxiosRequestConfig,
    ResponseType as AxiosResponseType,
} from "axios";
import axios from "axios";
import {appStateStore} from "../../../globalAppState";
import Cookies from "js-cookie";
import {isElectronEnvironment} from "../../../helper";
import {ErrorMessage} from "../interfaces/data/ErrorMessage";

/**
 * Helper function getting the URL of the API endpoint
 * @returns The base API URL
 */
export const getApiEndpoint = () => {
    return appStateStore.get().patientConfig?.apiUrl ?? process.env.GATSBY_API_BASE_URL ?? "https://mockserver.cloudoculus.dev/api";
};

/** Default timeout length for API requests in milliseconds. */
export const ApiTimeout = !process.env.GATSBY_API_TIMEOUT || isNaN(Number(process.env.GATSBY_API_TIMEOUT))
    ? 60000
    : Number(process.env.GATSBY_API_TIMEOUT);
const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {

        const token = localStorage.getItem("token") || "";
        if (config?.url?.includes("/patient") || config?.url?.includes("/measurement")) {
            console.log(`Using X-Client-Token "${token}"`);
            config.headers["x-client-token"] = token;
        }
        const apiUrl = appStateStore.get().patientConfig?.apiUrl;
        if (apiUrl?.startsWith("https://mockserver.cloudoculus.dev/") || apiUrl?.startsWith("https://oculus-mock-server-7d85c3a8268c.herokuapp.com/")) {
            if (isElectronEnvironment()) {
                config.headers["Authorization"] = "Bearer " + process.env.GATSBY_API_TOKEN;
            } else {
                config.headers["Authorization"] = "Bearer " + Cookies.get("token");
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

/** Extended Error class for handling API error responses. */
export class ApiError extends Error {
    /** The error message returned by the backend (if available). */
    errorMessage?: ErrorMessage;

    constructor(message: string, errorMessage?: ErrorMessage) {
        super(message);
        this.errorMessage = errorMessage;
    }
}


type ApiReturn<T> = Pick<AxiosResponse<T>, "data" | "headers">;

/**
 * A helper function that sends an HTTP request to the Backend-API and returns the response.
 *
 * @param method - HTTP method, e.g. GET or POST
 * @param endpoint - API endpoint the request is made to, e.g. `/info`
 * @param data - Payload of the request
 * @param params - Optional URI parameters for the request
 * @param responseType - Specifies the response type that should be expected from the server. Default is `"json"`.
 *
 * @returns Promise that resolves to an object containing the response data
 * and headers if the request was successful, and rejects with an error if not.
 */
export async function request<T, D = undefined, E = undefined>(
    method: string,
    endpoint: string,
    data: D,
    params: E,
    responseType: AxiosResponseType = "json",
): Promise<ApiReturn<T>> {
    const config: AxiosRequestConfig = {
        method,
        url: `${getApiEndpoint()}${endpoint}`,
        params,
        data,
        timeout: ApiTimeout,
        responseType,
    };
    // HACK: Add support for simple JSON values (like a string, number, boolean, null) as body
    if (config.data !== undefined && (typeof config.data !== "object" || config.data === null)) {
        config.data = JSON.stringify(data);
        config.headers = {
            ...config.headers,
            "Content-Type": "application/json",
        };
    }
    try {
        const {data, headers} = await axiosInstance.request<T>(config);
        return {data, headers};
    } catch (err) {
        const axiosError = err as AxiosError<ErrorMessage>;
        const reason = axiosError.message || "Unknown";
        let errorMessage: ErrorMessage | undefined;
        if (axiosError.response?.data?.message) {
            errorMessage = axiosError.response.data;
        }
        throw new ApiError(`${method} request to ${endpoint} failed: ${reason}`, errorMessage);
    }
}

/**
 * A helper function that sends an POST request to the Backend-API and returns the response.
 *
 * @param endpoint - API endpoint the request is made to, e.g. `/info`
 * @param data - Payload of the request
 *
 * @returns Promise that resolves to an object containing the response data
 * and headers if the request was successful, and rejects with an error if not.
 */
export function post<T, D>(endpoint: string, data: D): Promise<ApiReturn<T>> {
    return request("POST", endpoint, data, undefined);
}

/**
 * A helper function that sends an GET request to the Backend-API and returns the response.
 *
 * @param endpoint - API endpoint the request is made to, e.g. `/info`
 * @param params - Optional URI parameters for the request
 *
 * @returns Promise that resolves to an object containing the response data
 * and headers if the request was successful, and rejects with an error if not.
 */
export function get<T, E = undefined>(endpoint: string, params?: E): Promise<ApiReturn<T>> {
    return request("GET", endpoint, undefined, params);
}

/**
 * A helper function that sends an PUT request to the Backend-API and returns the response.
 *
 * @param endpoint - API endpoint the request is made to, e.g. `/info`
 * @param data - Payload of the request
 *
 * @returns Promise that resolves to an object containing the response data
 * and headers if the request was successful, and rejects with an error if not.
 */
export function put<T, D>(endpoint: string, data: D): Promise<ApiReturn<T>> {
    return request("PUT", endpoint, data, undefined);
}

/**
 * A helper function that sends an DELETE request to the Backend-API and returns the response.
 *
 * @param endpoint - API endpoint the request is made to, e.g. `/info`
 * @param params - Optional URI parameters for the request
 *
 * @returns Promise that resolves to an object containing the response data
 * and headers if the request was successful, and rejects with an error if not.
 */
export function del<T, E = undefined>(endpoint: string, params?: E): Promise<ApiReturn<T>> {
    return request("DELETE", endpoint, undefined, params);
}
