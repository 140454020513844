import {navigate} from "gatsby";
import {LightDensityClass} from "./backend/api/interfaces/custom-datatypes/LightDensityClass";
import {ErrorMessage} from "./backend/api/interfaces/data/ErrorMessage";
import {useEffect, useState} from "react";
import {PreviewListResponse} from "./backend/api/interfaces/response/PreviewListResponse";
import PatientResponse from "./backend/api/interfaces/response/PatientResponse";
import {Position} from "./backend/api/interfaces/data/Position";
import {decodeConfigToken} from "./utils/configToken";
import {z} from "zod";
import {ProgramType} from "./backend/api/interfaces/custom-datatypes/ProgramType";
import {AutomaticKineticProgram, CombiProgram, Program, StaticProgram} from "./backend/api/interfaces/data/Program";
import {ExaminationHeader} from "./backend/api/interfaces/data/ExaminationHeader";
import {DeviceName} from "./backend/api/interfaces/data/DeviceName";
import {NumberPointColor} from "@oculus/component-library";
import {KineticMeasurementValue} from "./backend/api/interfaces/data/KineticMeasurementValue";
import {
    convertIsopterConfigToSimpleIsopterConfig, SimpleIsoptere,
} from "./components/examinations/manualKinetic/IsoptereModesController";
import {Color} from "./backend/api/interfaces/custom-datatypes/Color";
import {ApiError} from "./backend/api/core/Api";
/**
 * This function is used to convert a program typ to a user-friendly string representation.
 *
 * @param v - The program type
 * @returns A pretty, user-friendly text describing the program.
 */
export function programTypeToString(v: ProgramType): string {
    return ({
        [ProgramType.Static]: "Statische Untersuchung",
        [ProgramType.AutomaticKinetic]: "Kinetische Untersuchung",
        [ProgramType.Combi]: "Kombi-Untersuchung",
    } satisfies { [V in ProgramType]: string })[v] ?? v;
}

/**
 * Checks if a program includes `staticParameters`.
 *
 * @param program - The program to check.
 * @returns A boolean indicating whether the program includes `staticParameters`.
 */
export function hasProgramStatic(program: Program | null | undefined): program is StaticProgram | CombiProgram {
    return program?.type === ProgramType.Static || program?.type === ProgramType.Combi;
}

/**
 * Checks if a program includes `kineticParameters`.
 *
 * @param program - The program to check.
 * @returns A boolean indicating whether the program includes `kineticParameters`.
 */
export function hasProgramKinetic(program: Program | null | undefined): program is AutomaticKineticProgram | CombiProgram {
    return program?.type === ProgramType.AutomaticKinetic || program?.type === ProgramType.Combi;
}

/**
 * Converts sphere, cylinder, and axial length parameters to a string formatted for display to the user.
 *
 * @param sphere - The curvature of the lens
 * @param cylinder - The lens cylinder component
 * @param axialLength - The length of the eye's visual axis
 * @returns The string representation
 *
 * @see LensCorrection
 */
export function getCorrectionWithUnits(
    sphere: number,
    cylinder: number,
    axialLength: number
) {
    return `${sphere ? sphere.toLocaleString("de") + " dpt; " : ""} 
            ${cylinder ? cylinder.toLocaleString("de") + " dpt; " : ""}
            ${
        axialLength ? axialLength.toLocaleString("de") + "° " : ""
    }`;
}

/**
 * Determines if the current execution environment is Electron.
 *
 * @returns Returns true if running in Electron.
 */
export function isElectronEnvironment() {
    const checks = {
        isRenderer: () =>
            typeof window !== "undefined" &&
            typeof window.process === "object" &&
            (process as any).type === "renderer",
        isMainProcess: () =>
            typeof process !== "undefined" &&
            typeof process.versions === "object" &&
            !!process.versions.electron,
        isNodeIntegration: () =>
            typeof navigator === "object" &&
            true &&
            navigator.userAgent.indexOf("Electron") >= 0,
    };

    return checks.isMainProcess() || checks.isRenderer() || checks.isNodeIntegration();
}

/**
 * Closes the application. The specific action depends on the execution environment.
 *
 * - If the application is running in an Electron environment, it closes the current window.
 * - If not, it navigates to a blank webpage.
 */
export function exitApp() {
    suppressNextUnloadWarning = true;
    if (isElectronEnvironment()) {
        window.close();
    } else {
        void navigate("about:blank");
    }
}

/**
 * Converts {@link LightDensityClass} to a string for display to the user.
 *
 * @param c - The enum value
 * @returns The string representation
 */
export function lightDensityClassToString(c: LightDensityClass): string {
    switch (c) {
        case LightDensityClass.One:
            return "Klasse 1";
        case LightDensityClass.Two:
            return "Klasse 2";
        case LightDensityClass.Three:
            return "Klasse 3";
        case LightDensityClass.Four:
            return "Klasse 4";
        case LightDensityClass.Five:
            return "Klasse 5";
        case LightDensityClass.Six:
            return "Klasse 6";
        default:
            return c satisfies never;
    }
}

/**
 * Function converts an {@link ErrorMessage} into a string representation.
 * All placeholders are substituted by associated values.
 *
 * @param error - The error to be converted into string
 * @return A string representation of the error
 */
export function errorMessageToString(error: ErrorMessage): string {
    return error.message.replaceAll(/%\d+/g, (s) => `${error.arguments[Number(s.slice(1))]?.value}`);
}

/**
 * Converts an unknown error to a string safely.
 *
 * Handles {@link ApiError}  and standard JavaScript errors by extracting and
 * formatting relevant details. Falls back to a generic string conversion if
 * the error type is unrecognized.
 *
 * @param err - The error to convert to a string.
 * @returns A string representation of the error.
 */
export function errorToString(err: unknown): string {
    if (err instanceof ApiError && err.errorMessage) {
        try {
            return errorMessageToString(err.errorMessage);
        } catch {
        }
    }
    if (((err): err is Error | null => typeof err === "object")(err) && err?.name === "string" && err.name && typeof err?.message === "string" && err.message) {
        return `${err.name}: ${err.message}`;
    }
    return `${err}`;
}

/**
 * Shuffles the elements of a given array using Fisher-Yates shuffling algorithm.
 * This function does not mutate the original array. It returns a new array with elements shuffled.
 *
 * @template T - The type of items in the array.
 * @param array - The array to shuffle.
 * @returns The shuffled array.
 */
export function shuffle<T>(array: T[]): T[] {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i -= 1) {
        const pos = Math.trunc(Math.random() * (i + 1));
        if (pos !== i) {
            [shuffledArray[i], shuffledArray[pos]] = [shuffledArray[pos], shuffledArray[i]];
        }
    }
    return shuffledArray;
}

/**
 * Picks a random element from the given array.
 *
 * @template T - Type of the elements in the array.
 * @param array - The array from which to pick a random element.
 * @returns A random element from the provided array.
 */
export function pickRandom<T>(array: T[]) {
    return array[Math.trunc(Math.random() * array.length)];
}

/**
 * Compares two Position objects for equality.
 *
 * @param a - A Position object.
 * @param b - Another Position object.
 * @returns A boolean indicating whether the two positions are equal.
 */
export function isEqualPosition(a: Position, b: Position): boolean {
    return a.x === b.x && a.y === b.y;
}

export const useEvaluationPreviewListState = () => {
    const [evaluationPreviewList, setEvaluationPreviewList] = useState<{
        patient: PatientResponse | undefined,
        requestPage: number,
        response?: PreviewListResponse,
    }>({patient: undefined, requestPage: 0});

    return {evaluationPreviewList, setEvaluationPreviewList};
};

/**
 * A global flag used to suppress the warning for the next unload event.
 *
 * When `true`, the application's beforeunload handler will allow the
 * window to close without displaying a warning dialog. This is typically
 * set before programmatically closing the application to prevent unnecessary
 * prompts to the user.
 */
let suppressNextUnloadWarning = false;

/**
 * Handles window unload event and sets 'reload' state based on session storage.
 */
export const useHandleBeforeUnload = (setReload: (reload: boolean) => void) => {
    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (!suppressNextUnloadWarning) {
                event.preventDefault();
            }
            suppressNextUnloadWarning = false;
            sessionStorage.setItem('reload', 'true');
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        if (sessionStorage.getItem('reload') === 'true') {
            setReload(true);
        }

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [setReload]);
};

/**
 * Retrieves the start arguments that the external patient management program provides when initiating this Electron app.
 *
 * The arguments must have the following format: `….EXE : A B C D E F`, where each letter represents a parameter.
 *
 * @return Returns `null` if no parameters are provided by the external program, otherwise, it returns the arguments after `:` in an array.
 * @see ServerProtocol.pdf (2.0.1 Mandatory positional command line arguments)
 **/
export function getGoProgramArgs() {
    if (!isElectronEnvironment()) {
        return null;
    }
    const goProgramArgsToken = sessionStorage.getItem("goProgramArgs");
    if (goProgramArgsToken) {
        try {
            return decodeConfigToken(goProgramArgsToken, z.array(z.string()))
        } catch (e) {
            console.error(e);
        }
    }
    return null;
}

/**
 * Splits an array into chunks of a specified size.
 *
 * @template T The type of elements in the input array.
 * @param arr The array to split into chunks.
 * @param size The size of each chunk.
 * @returns An array of arrays, where each inner array is a chunk of the original array.
 */
export function sliceArray<T>(arr: T[], size: number): T[][] {
    const chunks: T[][] = [];
    for (let i = 0; i < arr.length; i += size) {
        chunks.push(arr.slice(i, i + size));
    }
    return chunks;
}

/**
 * Pauses the execution of an asynchronous function for a specified number of milliseconds.
 *
 * @param ms - The number of milliseconds to pause. If not provided, the function defaults to 0 milliseconds.
 * @returns A Promise that resolves after the specified number of milliseconds.
 */
export function asyncSleep(ms?: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Converts a boolean status to its corresponding string representation for display to the user.
 *
 * @param status - A boolean value representing the state.
 *                  `true` corresponds to On, and `false` corresponds to Off.
 * @returns A string representing the status.
 */
export function booleanStateToString(status: boolean) {
    return status ? "An" : "Aus";
}

/**
 * Compares two values deeply to determine if they are equivalent.
 *
 * This function performs a deep comparison between two values, checking if their properties and nested properties
 * are equal. It handles primitive values, objects, and arrays.
 *
 * @param a - The first value to compare. It can be of any type.
 * @param b - The second value to compare. It can be of any type.
 * @returns A boolean indicating whether the two values are deeply equal.
 */
export function deepCompare(a: any, b: any) {
    if (a === b) {
        return true;
    }
    if (typeof a !== "object" || typeof b !== "object" || a === null || b === null || Array.isArray(a) !== Array.isArray(b)) {
        return false;
    }
    const entriesA = Object.entries(a);
    const entriesB = Object.entries(b);
    if (entriesA.length !== entriesB.length) {
        return false;
    }
    entriesA.sort(([keyA], [keyB]) => keyA.localeCompare(keyB));
    entriesB.sort(([keyA], [keyB]) => keyA.localeCompare(keyB));
    for (let i = 0; i < entriesA.length; i += 1) {
        const [keyA, valueA] = entriesA[i];
        const [keyB, valueB] = entriesB[i];
        if (keyA !== keyB || !deepCompare(valueA, valueB)) {
            return false;
        }
    }
    return true;
}

/**
 * Generates a universally unique identifier (UUID).
 *
 * @returns A string representing the generated UUID.
 */
export function generateUuid() {
    return [8, 4, 4, 4, 12]
        .map((length) => Array.from({length}, () => Math.trunc(Math.random() * 16).toString(16)).join(""))
        .join("-");
}

/** Sanitizes the examination header by ensuring that the `deviceName` field is a string. */
export function sanitizeExaminationHeader<T extends ExaminationHeader | null | undefined>(header: T): T {
    if (!header || typeof header.deviceName !== "object") {
        return header;
    }
    return {
        ...header,
        deviceName: (header.deviceName as DeviceName).name,
    };
}

/**
 * Converts a `Color` enumeration value to a corresponding `NumberPointColor` enumeration value.
 *
 * @param color - The `Color` enumeration value to be converted.
 * @returns The corresponding `NumberPointColor` enumeration value.
 */
export function convertColorToNumberPointColor(color: Color): NumberPointColor {
    return {
        [Color.Red]: NumberPointColor.RED,
        [Color.Green]: NumberPointColor.GREEN,
        [Color.Black]: NumberPointColor.BLACK,
    }[color];
}

/**
 * Converts an array of kinetic values into isopteres for `IsoptereModes`.
 *
 * @param values - Array of kinetic values to be converted.
 * @returns An array of simple isopters.
 *
 */
export function convertKineticValuesToSimpleIsopteres(values: Pick<KineticMeasurementValue, "isopterIndex" | "stimulusSize" | "goldmannBrightness" | "position" | "direction">[]): SimpleIsoptere[] {
    const isopteres: SimpleIsoptere[] = [];
    for (const value of values) {
        isopteres[value.isopterIndex] ??= {
            elements: [],
            ...convertIsopterConfigToSimpleIsopterConfig({
                stimulusSize: value.stimulusSize,
                brightness: value.goldmannBrightness
            }),
            isopterIndex: value.isopterIndex,
        };
        isopteres[value.isopterIndex].elements.push({position: value.position, direction: value.direction});
    }
    // Make lists dense
    return Object.values(isopteres);
}