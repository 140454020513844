/**
 * This module is for injecting additional elements on the client-side.
 * It doesn't affect server-side rendering.
 *
 * Specifically, this is where Userback is integrated.
 */

// noinspection JSUnusedGlobalSymbols

import React, {ReactNode, useEffect} from "react";
import "./src/styles/global.css";
import Userback from "@userback/widget";
import AppListener from "./src/components/appListener";
import AppUrlListener from "./src/components/appUrlListener";
import ScreenSizePopup from "./src/components/ScreenSizePopup";
import "simplebar-react/dist/simplebar.min.css";
import ErrorScreen from "./src/components/ErrorScreen";

/**
 * Generally, Gatsby performs an automatic scroll-to-top action during page navigation.
 * However, in some scenarios,
 * you may want to inhibit this behavior to maintain the current scroll position.
 *
 * This function intervenes to restrict that default scrolling behavior.
 * It achieves this by checking for the `disableScrollUpdate` property in the history state.
 *
 * @see https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#shouldUpdateScroll
 *
 * @example
 * // Navigate maintaining the current scroll position
 * navigate("/target-path", {state: { disableScrollUpdate: true } })
 */
export const shouldUpdateScroll = ({routerProps}: { routerProps: Object }) => {
    try {
        // @ts-ignore
        return !routerProps.location.state?.disableScrollUpdate;
    } catch (e) {
        console.error(e);
        return true;
    }
}

/**
 * Wrap each page element.
 *
 * @see https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapPageElement
 */
export const wrapPageElement = ({element}: { element: ReactNode }) => {
    useEffect(() => {
        if (process.env.GATSBY_USERBACK_TOKEN) {
            void (Userback(process.env.GATSBY_USERBACK_TOKEN));
        }
    }, []);
    return (<ErrorScreen>
        <AppUrlListener/>
        <AppListener/>
        {element}
        <ScreenSizePopup/>
    </ErrorScreen>);
};
